import { ref } from "vue";
import { useApi } from "@/use/api/useApi";
import { storeToRefs } from "pinia";

import useUsersStore from "@/stores/users/useUsersStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import useAuthStore from "@/stores/auth/useAuthStore";

import type { AdminI, ManagerI } from "@/stores/auth/UserInterface";
import { getErrorMessage } from "@/use/users/useUserModifier";

export function useEmailModifier(isAdminMode: boolean) {

  const usersStore = useUsersStore();
  const { usersList } = storeToRefs(usersStore);

  const managerStore = useTmManagerStore();
  const { adminsList } = storeToRefs(managerStore);

  const authStore = useAuthStore();
  const { me } = storeToRefs(authStore);

  function onUpdateMe(id: number, email: string) {
    if (me.value.id === id) me.value.login = email
  }

  function onEditUserLogin(id: number, email: string) {
    const index = usersList.value.findIndex((e: ManagerI) => e.id === id)
    if (index !== -1) usersList.value[index].login = email
  }

  function onEditAdminLogin(id: number, email: string) {
    const index = adminsList.value.findIndex((e: AdminI) => e.id === id)
    if (index !== -1) adminsList.value[index].login = email
  }

  const message = ref('');
  const loader = ref(false);

  function updateEmail(id: number, email: string) {
    loader.value = true;
    message.value = '';

    return new Promise((resolve, reject) => {
      useApi().security.patchLogin(id, { login: email })
        .then(() => {
          if (isAdminMode) onEditAdminLogin(id, email)
          else onEditUserLogin(id, email)

          onUpdateMe(id, email)
          resolve(null)
        })
        .catch((error) => {
          message.value = getErrorMessage(error?.response?.status, error?.data, 'Произошла ошибка при обновлении email')
        })
        .finally(() => loader.value = false)
    })
  }

  return {
    loader,
    message,
    updateEmail,
  }
}
